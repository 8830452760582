import moment from 'moment';
import flatpickr from 'flatpickr';

export const formatPauseUntilInput = () => {
    const pauseUntilInput = document.getElementById('id_pause_until');

    const today = moment.utc().hour(0).minute(0).second(0);
    const min = pauseUntilInput.min || today.add(1, "days").format("YYYY-MM-DD");
    const max = pauseUntilInput.max || today.add(90, "days").format("YYYY-MM-DD");

    flatpickr(pauseUntilInput, {
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        minDate: min,
        maxDate: max,
        disableMobile: true,
    });
};

export const handlePauseReasonChange = () => {
    const pauseReasonSelect = document.getElementById('id_pause_reason');
    const additionalInfoInput = document.getElementById('id_pause_extra');

    pauseReasonSelect.addEventListener('change', (event) => {
        const isOther = event.target.value === 'other';
        additionalInfoInput.required = isOther;
        additionalInfoInput.parentElement.querySelector('.text-muted').hidden = isOther;
        console.log(isOther);
    });
}
