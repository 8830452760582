// base.js
//
// All Javascript and CSS common to all pages including the public site, dashboard, but not the admin.

// Import all Sass/CSS files to be compiled into base.css
import './base.scss';

// Import miscellaneous JS files
import './js/alerts.js'
import './js/forms.js';
import './js/marquee.js';
import './js/menu_toggler.js';
import './js/pronouns.js';
import './js/questionnaire.js';
import './js/registration_password.js';
import './js/scroll_detect.js';
import './js/service_choice_option.js';
import './js/text_reveal.js';
import './scheduling/scheduling.js';
import './js/slider.js';
import './js/favicon_mode.js'
import './js/copypasta.js';
import './js/phone.js';
import './js/service.js';
import './js/signup_form.js';

// Import optional billing helpers
import { initBilling, waitForStripe } from './js/billing.js';

window.waitForStripe = waitForStripe;
window.initBilling = initBilling;

// Import optional broadband label helpers
import { initBroadbandLabel, displayBroadbandLabel } from './js/broadband_label.js';

window.initBroadbandLabel = initBroadbandLabel;
window.displayBroadbandLabel = displayBroadbandLabel;

// Import optional service summary helpers
import { updateServiceSummary } from './js/service_summary.js';

window.updateServiceSummary = updateServiceSummary;

// Import cancel sub reasons helpers
import { initializeSubReasonOptions } from './js/cancel.js';

window.initializeSubReasonOptions = initializeSubReasonOptions;

// Import pause subscription helpers
import { formatPauseUntilInput, handlePauseReasonChange } from './js/pause.js';

window.formatPauseUntilInput = formatPauseUntilInput;
window.handlePauseReasonChange = handlePauseReasonChange;

// Import optional service options helpers
import { generateServiceOptions, generateServiceSelectOptions, generateUnitSelectOptions } from './js/service_options.js';

window.generateServiceOptions = generateServiceOptions;
window.generateServiceSelectOptions = generateServiceSelectOptions;
window.generateUnitSelectOptions = generateUnitSelectOptions;
